import React from 'react'
import SignUpForm from '../Components/SignUpForm'

const SignUpPage = () => {
  return (
    <div>
      <SignUpForm/>
    </div>
  )
}

export default SignUpPage
